import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@oper-client/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DynamicFormModule } from '@oper-client/shared/util-dynamic-form';
import { UtilFormattingModule } from '@oper-client/shared/util-formatting';

import { DocumentsCardComponent } from './containers/cards/documents-card/documents-card.component';
import { PreApprovalCardComponent } from './containers/cards/pre-approval-card/pre-approval-card.component';
import { NavigationHeaderComponent } from './components/navigation-header/navigation-header.component';
import { BorrowerStatusCardComponent } from './containers/cards/borrower-status-card/borrower-status-card.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NavigationSlideItemComponent } from './components/navigation-slide-item/navigation-slide-item.component';
import { NavigationPaneComponent } from './components/navigation-pane/navigation-pane.component';
import { SignIconComponentComponent } from './icons/sign-icon-component/sign-icon-component.component';
import { OfficeSelectorCardComponent } from './containers/cards/office-selector-card/office-selector-card.component';
import { OfficeSelectorModalComponent } from './components/office-selector-modal/office-selector-modal.component';
import { OfficeSelectorComponent } from './components/office-selector/office-selector.component';
import { BorrowerModeContainer } from './containers/steps/borrower-mode/borrower-mode.container';
import { BorrowerModeNewContainer } from './containers/steps/borrower-mode-new/borrower-mode-new.container';
import { ClientCombinedContainer } from './containers/steps/client-combined-container/client-combined-container.component';
import { ClientContainer } from './containers/steps/client-container/client-container.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { FinancialAssetContainer } from './containers/steps/financial-asset-container/financial-asset-container.component';
import { FlowClosureContainer } from './components/flow-closure/flow-closure.container';
import { IncomeContainerComponent } from './containers/steps/income-container/income-container.component';
import { LiabilityContainerComponent } from './containers/steps/liability-container/liability-container.component';
import { OfficeSelectorContainer } from './containers/steps/office-selector-container/office-selector.container';
import { PreapprovalContainer } from './containers/steps/preapproval-container/preapproval-container.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RealtyContainer } from './containers/steps/realty-container/realty-container.component';
import { StepButtonsComponent } from './components/step-buttons/step-buttons.component';
import { StepHeadComponent } from './components/step-head/step-head.component';
import { StepLoaderOverlayComponent } from './components/step-loader-overlay/step-loader-overlay.component';
import { TaxDeclarationContainer } from './containers/steps/tax-declaration-container/tax-declaration-container.component';
import { UploadTaxStatementComponent } from './components/upload-tax-statement/upload-tax-statement.component';
import { OwnFundsContainerComponent } from './containers/steps/own-funds-container/own-funds-container.component';
import { FinancialDataComponent } from './containers/steps/financial-data/financial-data.component';
import { MortgageReportComponent } from './containers/steps/mortgage-report/mortgage-report.component';
import { ContactFormModalComponent } from './components/contact-form-modal/contact-form-modal.component';
import { MortgageSimulationFlowContainer } from './containers/steps/mortgage-simulation-flow/mortgage-simulation-flow.container';
import { MortgageOwnFundsContainer } from './containers/steps/mortgage-own-funds/mortgage-own-funds.container';
import { MortgageIncomeContainer } from './containers/steps/mortgage-income/mortgage-income.container';
import { MortgageRealtyContainer } from './containers/steps/mortgage-realty/mortgage-realty.container';
import { MortgageReportProgressBarComponent } from './components/mortgage-report-progress-bar/mortgage-report-progress-bar.component';
import { MortgageReportAffordabilityCheckComponent } from './components/mortgage-report-affordability-check/mortgage-report-affordability-check.component';
import { CheckMessageComponent } from './components/check-message/check-message.component';
import { MortgageReportCostBreakdownComponent } from './components/mortgage-report-cost-breakdown/mortgage-report-cost-breakdown.component';
import { MortgageReportPaymentComponent } from './components/mortgage-report-payment/mortgage-report-payment.component';
import { ContactButtonComponent } from './components/contact-button/contact-button.component';
import { MortgageLiabilityContainer } from './containers/steps/mortgage-liability/mortgage-liability.container';
import { MortgageReportPaymentDiagramComponent } from './components/mortgage-report-payment-diagram/mortgage-report-payment-diagram.component';
import { MortgageReportPaymentMonthlyComponent } from './components/mortgage-report-payment-monthly/mortgage-report-payment-monthly.component';
import { MortgageReportAmountComponent } from './components/mortgage-report-amount/mortgage-report-amount.component';
import { FinancialInfoBoxComponent } from './components/financial-info-box/financial-info-box.component';
import { ProgressStepperComponent } from './components/progress-stepper/progress-stepper.component';
import { MortgageYourHomeContainer } from './containers/steps/mortgage-your-home/mortgage-your-home.container';
import { MortgageCoLivingGroupContainer } from './containers/steps/mortgage-co-living-group/mortgage-co-living-group.container';
import { MortgageNewBuildContainer } from './containers/steps/mortgage-new-build/mortgage-new-build.container';
import { MortgageRegionContainer } from './containers/steps/mortgage-region/mortgage-region.container';
import { MortgageRenovateExistingRealtyContainer } from './containers/steps/mortgage-renovate-existing-realty/mortgage-renovate-existing-realty.container';
import { MortgageExistingRealtyToRenovateContainer } from './containers/steps/mortgage-existing-realty-to-renovate/mortgage-existing-realty-to-renovate.container';
import { RealtyAdvancedContainer } from './containers/steps/realty-advanced/realty-advanced.container';
import { PreapprovalSummaryModalComponent } from './components/preapproval-summary-modal/preapproval-summary-modal.component';
import { PreapprovalSummaryComponent } from './components/preapproval-summary/preapproval-summary.component';
import { AppointmentBookingCardComponent } from './containers/cards/appointment-booking-card/appointment-booking-card.component';
import { YourMortgageContainer } from './containers/steps/your-mortgage/your-mortgage.container';
import { ClientDetailComponent } from './components/client-detail/client-detail.component';
import { MortgageReportPaymentMultipleComponent } from './components/mortgage-report-payment-multiple/mortgage-report-payment-multiple.component';
import { MortgageReportOfferCardComponent } from './components/mortgage-report-offer-card/mortgage-report-offer-card.component';
import { MortgageReportOfferCardChComponent } from './components/mortgage-report-offer-card-ch/mortgage-report-offer-card-ch.component';
import { MortgageReportOfferCardBeComponent } from './components/mortgage-report-offer-card-be/mortgage-report-offer-card-be.component';
import { MortgageReportPaymentAprcTooltipComponent } from './components/mortgage-report-payment-aprc-tooltip/mortgage-report-payment-aprc-tooltip.component';
import { OfferConfirmationModalComponent } from './components/offer-confirmation-modal/offer-confirmation-modal.component';
import { ContactFormCardComponent } from './components/contact-form-card/contact-form-card.component';
import { DocumentChecklistProgressCardComponent } from './containers/cards/document-checklist-progress-card/document-checklist-progress-card.component';
import { NavigationService } from './services/navigation.service';
import { SignupHeaderComponent } from './components/signup-header/signup-header.component';
import { MortgageSimulatorService } from '@oper-client/shared/api-v2.0';
import { MortgageHousingTypeComponent } from './containers/steps/mortgage-housing-type/mortgage-housing-type.component';
import { MortgageYourProjectContainer } from './containers/steps/mortgage-your-project/mortgage-your-project.container';
import { MortgageRealtyBuildContainer } from './containers/steps/mortgage-realty-build/mortgage-realty-build.container';
import { UtilGoogleMapsModule } from '@oper-client/shared/util-google-maps';
import { BlockerDisplayComponent } from './components/blocker-display/blocker-display.component';

@NgModule({
	imports: [
		CommonModule,
		DynamicFormModule,
		FontAwesomeModule,
		RouterModule,
		TranslateModule,
		UiModule,
		UtilFormattingModule,
		FormsModule,
		ReactiveFormsModule,
		UtilGoogleMapsModule,
	],
	declarations: [
		BorrowerModeContainer,
		BorrowerModeNewContainer,
		BorrowerStatusCardComponent,
		ClientCombinedContainer,
		ClientContainer,
		DocumentsCardComponent,
		ErrorModalComponent,
		FinancialAssetContainer,
		FinancialDataComponent,
		FlowClosureContainer,
		HeaderComponent,
		IncomeContainerComponent,
		LiabilityContainerComponent,
		MortgageReportComponent,
		NavigationComponent,
		NavigationHeaderComponent,
		NavigationHeaderComponent,
		NavigationPaneComponent,
		NavigationSlideItemComponent,
		OfficeSelectorCardComponent,
		OfficeSelectorComponent,
		OfficeSelectorContainer,
		OfficeSelectorModalComponent,
		OwnFundsContainerComponent,
		PreApprovalCardComponent,
		PreapprovalContainer,
		ProgressBarComponent,
		RealtyContainer,
		SignIconComponentComponent,
		StepButtonsComponent,
		StepHeadComponent,
		StepLoaderOverlayComponent,
		TaxDeclarationContainer,
		UploadTaxStatementComponent,
		ContactFormModalComponent,
		MortgageSimulationFlowContainer,
		MortgageRealtyContainer,
		MortgageOwnFundsContainer,
		MortgageIncomeContainer,
		MortgageReportProgressBarComponent,
		MortgageReportAffordabilityCheckComponent,
		CheckMessageComponent,
		MortgageReportCostBreakdownComponent,
		MortgageReportPaymentComponent,
		ContactButtonComponent,
		MortgageLiabilityContainer,
		MortgageReportPaymentDiagramComponent,
		MortgageReportPaymentMonthlyComponent,
		MortgageReportAmountComponent,
		FinancialInfoBoxComponent,
		ProgressStepperComponent,
		MortgageYourHomeContainer,
		MortgageCoLivingGroupContainer,
		MortgageNewBuildContainer,
		MortgageRegionContainer,
		MortgageRenovateExistingRealtyContainer,
		MortgageExistingRealtyToRenovateContainer,
		RealtyAdvancedContainer,
		PreapprovalSummaryModalComponent,
		PreapprovalSummaryComponent,
		AppointmentBookingCardComponent,
		YourMortgageContainer,
		ClientDetailComponent,
		MortgageReportPaymentMultipleComponent,
		MortgageReportOfferCardComponent,
		MortgageReportOfferCardChComponent,
		MortgageReportOfferCardBeComponent,
		MortgageReportPaymentAprcTooltipComponent,
		OfferConfirmationModalComponent,
		ContactFormCardComponent,
		DocumentChecklistProgressCardComponent,
		SignupHeaderComponent,
		MortgageHousingTypeComponent,
		MortgageYourProjectContainer,
		MortgageRealtyBuildContainer,
		BlockerDisplayComponent,
	],
	exports: [
		BorrowerModeContainer,
		BorrowerModeNewContainer,
		BorrowerStatusCardComponent,
		ClientCombinedContainer,
		ClientContainer,
		DocumentsCardComponent,
		ErrorModalComponent,
		FinancialAssetContainer,
		FinancialDataComponent,
		FlowClosureContainer,
		HeaderComponent,
		IncomeContainerComponent,
		LiabilityContainerComponent,
		MortgageReportComponent,
		NavigationComponent,
		NavigationHeaderComponent,
		NavigationHeaderComponent,
		NavigationPaneComponent,
		NavigationSlideItemComponent,
		OfficeSelectorCardComponent,
		OfficeSelectorComponent,
		OfficeSelectorContainer,
		OfficeSelectorModalComponent,
		OwnFundsContainerComponent,
		PreApprovalCardComponent,
		PreapprovalContainer,
		ProgressBarComponent,
		RealtyContainer,
		SignIconComponentComponent,
		StepButtonsComponent,
		StepHeadComponent,
		StepLoaderOverlayComponent,
		TaxDeclarationContainer,
		UploadTaxStatementComponent,
		ContactFormModalComponent,
		MortgageSimulationFlowContainer,
		MortgageRealtyContainer,
		MortgageOwnFundsContainer,
		MortgageIncomeContainer,
		MortgageReportProgressBarComponent,
		MortgageReportAffordabilityCheckComponent,
		CheckMessageComponent,
		MortgageReportCostBreakdownComponent,
		MortgageReportPaymentComponent,
		ContactButtonComponent,
		MortgageLiabilityContainer,
		MortgageReportAmountComponent,
		FinancialInfoBoxComponent,
		ProgressStepperComponent,
		MortgageYourHomeContainer,
		MortgageCoLivingGroupContainer,
		MortgageNewBuildContainer,
		MortgageRegionContainer,
		MortgageRenovateExistingRealtyContainer,
		MortgageExistingRealtyToRenovateContainer,
		RealtyAdvancedContainer,
		AppointmentBookingCardComponent,
		PreapprovalSummaryComponent,
		PreapprovalSummaryModalComponent,
		YourMortgageContainer,
		MortgageReportPaymentMultipleComponent,
		MortgageReportOfferCardComponent,
		MortgageReportOfferCardChComponent,
		MortgageReportOfferCardBeComponent,
		MortgageReportPaymentAprcTooltipComponent,
		OfferConfirmationModalComponent,
		ContactFormCardComponent,
		DocumentChecklistProgressCardComponent,
		SignupHeaderComponent,
		MortgageHousingTypeComponent,
		MortgageYourProjectContainer,
		MortgageRealtyBuildContainer,
		BlockerDisplayComponent,
	],
	providers: [NavigationService, MortgageSimulatorService],
})
export class SelfServiceCommonModule {}
