import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOAN_APPLICATION_KEY, LoanApplicationState } from './loan-application.reducer';

export const selectState = createFeatureSelector<LoanApplicationState>(LOAN_APPLICATION_KEY);
export const getConfiguration = createSelector(selectState, (state) => state.configuration);
export const getActiveStep = createSelector(selectState, (state) => state.activeStep);
export const getData = createSelector(selectState, (state) => state.data);
export const getDataPoints = createSelector(selectState, (state) => state.dataPoints);
export const getSteps = createSelector(getConfiguration, (configuration) => configuration?.steps);
export const getActions = createSelector(selectState, (state) => state.actions);
export const getLoadSimulationActionState = createSelector(selectState, (state) => state.actions.loadSimulation);
export const getLoadLoanRequestActionState = createSelector(selectState, (state) => state.actions.loadLoanRequest);
export const getLoadClientsActionState = createSelector(selectState, (state) => state.actions.loadClients);
export const getLoadRealtiesActionState = createSelector(selectState, (state) => state.actions.loadRealties);
export const getUpdateLoanRequestActionState = createSelector(selectState, (state) => state.actions.updateLoanRequest);
export const getUpdateClientActionState = createSelector(selectState, (state) => state.actions.updateClient);
export const getUpdateRealtyActionState = createSelector(selectState, (state) => state.actions.updateRealty);
export const getUpdateCollateralRealtyActionState = createSelector(selectState, (state) => state.actions.updateCollateralRealty);
export const getLoadOffersActionState = createSelector(selectState, (state) => state.actions.loadOffers);
export const getLoadClientLiabilitiesActionState = createSelector(selectState, (state) => state.actions.loadClientLiabilities);
export const getLoadClientIncomesActionState = createSelector(selectState, (state) => state.actions.loadClientIncomes);
export const getCreateLoanRequestCommentActionState = createSelector(selectState, (state) => state.actions.createLoanRequestComment);
export const getUpdateClientLiabilityActionState = createSelector(selectState, (state) => state.actions.updateClientLiability);
